.svg-icon.trustbadge svg [fill]:not(.permanent):not(g) {
    fill: #fff !important
}

.review-line {
    color: #FE5D5D;
    text-align: center;
    margin-top: 4px;
    font-size: 14px;
    cursor: pointer;
}

.review-detail-row {
    list-style: none;

    .score {
        display: flex;
        height: 30px;
        align-items: center;
        margin: 6px 0;
    }
}

.svg-icon.trustbadge svg [fill]:not(.permanent):not(g) {
    fill: #fff !important
}

.offer-action-buttons {
    position: absolute;
    left: 0;
    bottom: 10px;
    padding: 0 12px;
    width: 100%;
}

@media screen and (max-width: 600px) {
    .offer-action-buttons {
        position: relative !important;
        left: unset !important;
        bottom: unset !important;
        padding: 0 !important;
        width: 100%;
    }
}

.vendorOfferItem {
    width: 100%;

    .vendorName {
            font-size: 14px;
            font-weight: bold;
    }
}

.dropbox {
    position: absolute !important;
        right: 30px !important;
}