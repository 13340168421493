//
// Theme style
//

// Skin
$skin: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';

// React vendors
@import 'core/vendors/react/general';
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import './core/vendors/plugins/prismjs';
@import './core/vendors/plugins/formvalidation';

.success-check {
    border-radius: 50px;
        width: 16px;
        height: 16px;
        background-color: rgb(80, 205, 137);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
}